
import React from "react"
import SiteConfig from "../../config/SiteConfig";
import { Layout, Wrapper, Header, Subline, PrevNext, SectionTitle, Content } from "../components"

import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


interface Props {
  data: {
    allMarkdownRemark: any
  }

}

const Collections = (props: Props) => {
  console.log(props)
  const items = props.data.allMarkdownRemark.edges;


  const CollectionItems = items.map((item: any) => {
    const {node: { fields: {slug}, frontmatter: { date, title, featuredImage}}} = item;
    const image = getImage(featuredImage)

    return <div>
        {image && <>
          <Link to={`/collections/curios/${slug}`}>
            <GatsbyImage image={image} alt={title}/>
            <h3>{title}</h3>
          </Link>
        </> }
      </div>
  })

  return (
    <Layout>
       <Helmet title={`Collections | ${SiteConfig.siteTitle}`} />
          <Header>
            <Link to="/">Home</Link>
            <SectionTitle>COLLECTIONS</SectionTitle>
          </Header>
          <Wrapper>
              <Content>
                <h2>CURIOS</h2>
                <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry gutter={'25px'}>
                {CollectionItems}
                </Masonry>
            </ResponsiveMasonry>

              </Content>
          </Wrapper>
    </Layout>
  )
}





export default Collections;

export const collectionsQuery = graphql`
{
  allMarkdownRemark(filter: {frontmatter: {type: {eq: "artifact"}}}, sort: { order: DESC, fields: [frontmatter___date] }, limit: 5) {
      edges {
          node {
              excerpt(pruneLength: 250)
              html
              id
              fields {
                  slug
              }
              frontmatter {
                  date
                  title
                  type
                  featuredImage {
                  childImageSharp {

                  gatsbyImageData(
                    width: 650
                    height: 650
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]

                    transformOptions: {
                      cropFocus: CENTER
                    }
                  )
                }
              }
            }
          }
      }
  }
}
`
